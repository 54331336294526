<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Electrical Classification" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <div
          class="fusion-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            class="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
            ><img
              src="@/assets/RiskManagement/electricalclassification-min.jpg"
              width="991"
              height="567"
              alt=""
              title="electricalclassification"
              class="img-responsive wp-image-12732"
              sizes="(max-width: 800px) 100vw, 600px"
          /></span>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            Proper design of electrical equipment is important in minimizing the
            probability of electrical hazards such as sparking, shorting,
            arcing, or localized initiation of out-of-place energetic/
            combustible material. The electrical classification approach
            utilized by SMS includes the following:
          </p>
          <ol>
            <li>
              Determine National Electric Code (NEC) hazardous material in each
              building and bay
            </li>
            <li>
              Determine NEC classification for identified hazardous materials
            </li>
            <li>
              Identify materials that are not classified as NEC hazardous
              materials, but may still present a hazard to electrical equipment
            </li>
            <li>
              Define all hazards relating to the identified hazardous materials
            </li>
            <li>
              Determine safeguards in place to prevent/ mitigate the effects of
              the identified hazards or evaluate credibility of defined hazards
            </li>
            <li>
              Issue electrical equipment recommendations to prevent/ mitigate
              hazards, accounting for safeguards and design safety
            </li>
          </ol>
          <p>
            In determining electrical equipment recommendations, emphasis is
            placed on 1) minimizing penetration of material into electrical
            equipment or enclosures, 2) minimizing exposure of threads to
            energetic material, and 3) limiting equipment/ enclosure surface
            temperatures to prevent thermal initiation of energetic/ combustible
            material.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Electrical Classification",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS can assist in electrical classification using the National Electric Code (NEC) and can help minimize electrical hazards."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
