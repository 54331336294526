<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent
      title="Electrical Classification, Hazards, and Protection Training"
      size="large"
    />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/Training/electricalclassification-min.jpg"
          width="991"
          height="567"
          alt=""
          title="electricalclassification"
          class="img-responsive wp-image-12732"
        />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            This course is intended as a trainer level course to provide those
            attending this course with the skills and knowledge to take this
            information and train other staff within their respective
            organizations on the electrical classification, standards,
            compliance, and industry practice as it relates to electrical
            equipment in hazardous areas. This course addresses the unique
            aspects of the electrical protection required to meet the NFPA, IEC,
            NEC, and ATEX standards for processes involving flammable vapors,
            liquids or gases, or combustible dust or fibers which may present
            the likelihood that a flammable or combustible concentration or
            quantity is present. This course will address the different
            materials that need to be classified, how to select the proper
            division/zone, and the process for selecting and maintaining the
            appropriate hardware for use in the associated areas along with
            documenting the process for regulatory compliance. Emphasis will be
            given to the benefits of implementing explosives safety protocol in
            conjunction with a PSM system (e.g., improved safety, enhanced
            product quality, cost avoidance, etc.).
          </p>
          <p>Course Content:</p>
          <ul>
            <li>Defining Hazardous locations per the regulation</li>
            <li>Class and Division criteria</li>
            <li>Safeguarding based on defined NFPA Class and Division</li>
            <li>Approved equipment and limitations</li>
            <li>Application of various requirements for installation</li>
            <li>Documentation and Hazardous zone communication</li>
            <li>ATEX IP Code, Category, etc.</li>
            <li>NEMA, UL, CSA ratings</li>
            <li>IEC and IP classification</li>
            <li>ATEX marking standards</li>
            <li>Documentation Requirements for PSM</li>
            <li>Workshops and application of course content</li>
          </ul>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Electrical Classification Hazards Protection Course",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "This course is intended as a trainer level course to provide those attending this course with the skills and knowledge to take this information and train other staff within their respective organizations on the electrical classification, standards, compliance, and industry practice as it relates to electrical equipment in hazardous areas. This course addresses the unique aspects of the electrical protection required to meet the NFPA, IEC, NEC, and ATEX standards."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
